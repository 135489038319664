import React from 'react';
import { Helmet } from 'react-helmet';
import styles from './styles.module.css';
import Tables from '../../components/Tables';

const CheckoutCart = () => (
  <main id="checkout-cart" className={styles.cartCon}>
    <Helmet>
      <title>Cart - G. Fox</title>
      <script>
        {`
          // Helper function to delay opening a URL until a gtag event is sent.
        // Call it in response to an action that should navigate to a URL.
        function gtagSendEvent(url) {
          var callback = function () {
            if (typeof url === 'string') {
              window.location = url;
            }
          };
          gtag('event', 'ads_conversion_begin_checkout_1', {
            'event_callback': callback,
            'event_timeout': 2000,
            // <event_parameters>
          });
          return false;
        }
      `}
      </script>
    </Helmet>
    <Tables.CheckoutItemsTable />
  </main>
);

export default CheckoutCart;
