/* eslint-disable no-unused-vars */
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  FiChevronDown, FiChevronLeft, FiChevronUp, FiChevronRight,
} from 'react-icons/fi';
import {
  Badge,
  Carousel, Empty, Image, message, Modal, Skeleton, Tabs, Tooltip,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { IoClose } from 'react-icons/io5';
import {
  ICart, ICategorySpecificInfo, IInventoryMaster, ISkuMaster,
} from '@bridgelabsdesign/gfox-api-client';
import { Helmet } from 'react-helmet';
import { StockHoldOptions } from '@bridgelabsdesign/gfox-api-client/dist/utils/enums';
import { getSkuMasters } from '@bridgelabsdesign/gfox-api-client/dist/client/sku-master-v2';
import { HeartOutlined, StarOutlined } from '@ant-design/icons';
import Link from 'antd/lib/typography/Link';
import { runInAction } from 'mobx';
import { key } from 'localforage';
import mixpanel from 'mixpanel-browser';
import styles from './styles.module.css';
import Typography from '../../components/Typography';
import Buttons from '../../components/Buttons';
import { CheckoutPath } from '../../utils/checkout';
import { useAuthContext } from '../../context/AuthContext';
import stores from '../../stores/stores';
import { isEmptyString } from '../../utils/strings';
import DescriptionTab from './DescriptionTab';
import RatingsTab from './RatingsTab';
import SafetyTab from './SafetyTab';
import TechnicalTab from './TechnicalTab';
import Spin from '../../components/Spin/Spin';
import Images from '../../assets';
import { getStockDifference, itemOutOfStock } from '../../utils/order/stock';
import useQuery from '../../utils/hooks/useQuery';
import { calcProductPrice, formatPrice } from '../../utils/pricing';
import Cards from '../../components/Cards';
import SearchStore from '../../stores/search';
import PricingInformation from '../../components/Pricing/PricingInformation';
import FallbackImage from '../../assets/illustrations/fallback-image';
import ProductFilterBy from '../Products/ProductFilterBy';
import Modals from '../../components/Modals';
import { getStockMessage } from '../../utils/order/stockMessage';
import SelectedItemsTable from '../../components/Tables/SelectedItemsTable';
import { imgURLPrefix } from '../../config/image';

const { Paragraph, Text, Title } = Typography;

type TabKeysType = 'description' | 'guidelines'
type TabKeysGuide = 'safety' | 'technical'

const ProductInformation = observer(() => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWishModalOpen, setIsWishModalOpen] = useState(false);
  const [isFavoritesModalOpen, setisFavoritesModalOpen] = useState(false);
  const [queryParams, setQueryParams] = useState<string | undefined>();
  const [activeTabKey, setActiveTabKey] = useState<TabKeysType>('description');
  const [activeTabGuide, setActiveTabGuide] = useState<TabKeysGuide>();
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [hasSelection, setHasSelection] = useState(false);
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const { currentClient, isLoggedIn } = useAuthContext();
  const [categoryPathQuery, setCategoryPathQuery] = useState('');
  const [showProducts, setShowProducts] = useState(false);
  const {
    cartStore, wishStore, inventoryStore, productSelectStore, searchStore, categoryStore, favoriteStore,
  } = stores;

  const history = useHistory();
  const query = useQuery();
  const reloadPage = useRef<boolean>(true);
  const currentPrice = useMemo(() => {
    const p = calcProductPrice(cartStore?.currentInvItem, cartStore?.lineItemCount);
    return p;
  }, [cartStore?.currentInvItem, cartStore?.lineItemCount]);

  const selectedImage = useMemo(() => {
    if (!cartStore.currentInvItem?.productImages) {
      return FallbackImage;
    }
    return cartStore.currentInvItem?.productImages[cartStore.activeImageIdx] ?? FallbackImage;
  }, [cartStore.currentInvItem, cartStore.activeImageIdx]);

  const technicalSpecFiles = cartStore.currentSkuItem?.technicalSpecificationFiles;
  const safetyDataSheetUrls = cartStore.currentSkuItem?.safetyDataSheetUrl;

  // Retrieve breadcrumb data from localStorage
  const breadCrumbData = JSON.parse(localStorage.getItem('breadcrumb') || '[]');
  useEffect(() => () => {
    cartStore.setSku(undefined);
  }, []);
  useEffect(() => {
    setHasSelection(cartStore.selectedProductItems.length > 0);
  }, [cartStore.selectedProductItems.length]);

  const stockMessage = getStockMessage(cartStore, cartStore.currentInvItem?.masterStock, currentClient);

  const isSkuListEmpty = () => inventoryStore.complementaryProducts.value.data.length === 0 && !inventoryStore.isLoading;
  const { categoryRef, subCategoryRef, sku } = useParams<{
    categoryRef?: string,
    subCategoryRef?: string,
    sku?: string,
  }>();

  const favoriteslistSelected = () => {
    if (favoriteStore.FavoritesItems.value.data.find((x) => x.inventoryMaster.id === favoriteStore.currentInvItem?.id)) {
      return true;
    }
    return false;
  };
  const wishlistSelected = () => {
    if (wishStore.wishItems.value.data.find((x) => x.inventoryMaster.id === wishStore.currentInvItem?.id)) {
      return true;
    }
    return false;
  };

  const tabHeaders = [
    {
      key: 'description',
      name: 'Description',
      component: (
        <DescriptionTab
          description={cartStore.currentInvItem?.description}
          items={[]}
          htmlContent={cartStore.currentSkuItem?.editorContentHtml}
        />
      ),
    },
    // TODO: used other meta data to fill out section
    // {
    //   name: 'Additional Information',
    //   component
    // },
    {
      key: 'Ratings',
      name: 'Ratings',
      component: <RatingsTab />,
    },
  ];

  const guideTab = [
    {
      key: 'technical' as TabKeysGuide,
      name: 'Technical Specification Files',
      component: <TechnicalTab />,
    },
    {
      key: 'safety' as TabKeysGuide,
      name: 'Safety Data Sheets',
      component: <SafetyTab />,
    },
  ];

  const handleGoBack = () => {
    history.goBack();
  };

  const handleOnViewModalSelection = async (invItems: IInventoryMaster[]) => {
    reloadPage.current = false;
    cartStore.pageInventoryMasterSku = undefined;

    cartStore.setProductSelectionQuery({
      colors: productSelectStore.selectedColors,
      filterBy: productSelectStore.filterBySelectedValues,
    });

    // TODO: we can avoid re-fetching from the api here
    const invItemsAsCart: ICart[] = invItems.map((item) => ({
      inventoryMasterId: item.id,
      inventoryMaster: item,
      quantity: cartStore.cartItems.value.data.find((x) => x.inventoryMasterId === item.id)?.quantity ?? 1,
    } as ICart));

    const newItems = invItemsAsCart.filter(
      (invItem) => !cartStore.selectedProductItems.some((cartItem) => cartItem.inventoryMasterId === invItem.inventoryMasterId),
    );
    cartStore.setSelectedProductItems([...cartStore.selectedProductItems, ...newItems]);
    setShowFilterModal(false);
    cartStore.pageInventoryMasterSku = cartStore.currentInvItem?.sku;
    history.push(`?stockCode=${cartStore.pageInventoryMasterSku ?? ''}`);
  };

  const handleAddToCart = async () => {
    if (!currentPrice || currentPrice?.excludingTax <= 0) {
      message.error('Could not add item to cart.');
      return;
    }

    setIsAddingToCart(true); // Start adding to cart
    const hide = message.loading('Adding item to cart', 0);
    try {
      if (cartStore.selectedProductItems.length > 0) {
        const inventoryMasters = cartStore.selectedProductItems.map((cart) => {
          // eslint-disable-next-line no-param-reassign
          cart.inventoryMaster.orderQty = cart.quantity;
          return cart.inventoryMaster;
        });
        await cartStore.addMultipleLineItems(currentClient, inventoryMasters, () => setIsModalOpen(true));
      } else {
        await cartStore.addLineItemToCart(currentClient, () => setIsModalOpen(true));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsAddingToCart(false); // Stop adding to cart
      hide();
    }

    try {
      mixpanel.track('Cart Add', {
        sku: cartStore.pageInventoryMasterSku,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleAddToFavorites = () => {
    if (!currentPrice || currentPrice?.excludingTax <= 0) {
      message.error('Could not add item to favorites list.');
    }
    favoriteStore.addLineItemToFavorites(currentClient, () => setisFavoritesModalOpen(true));
  };

  const handleGoToCheckout = () => {
    history.push(CheckoutPath.CART);
  };

  const handleGoToFavorites = () => {
    history.push('/products/favorites');
  };
  const handleGoToWishList = () => {
    history.push('/checkout/wishlist');
  };

  const handleChangeSelection = () => {
    setShowFilterModal(true);

    runInAction(() => {
      productSelectStore.filterByInv.value.data = cartStore.invList.value.data;
      productSelectStore.selectedColors = [];
      productSelectStore.filterBySelectedValues = [];
    });

    productSelectStore.loadFilterBy();
  };

  const queryBase64ToJSON = <T, >(name: string): T | null => {
    const base64 = query.get(name);
    if (base64 == null) {
      return null;
    }
    const colorsString = Buffer.from(base64, 'base64').toString('utf8');
    try {
      return JSON.parse(colorsString) as T;
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    cartStore.setActiveImageIdx(0);
  }, []);

  useEffect(() => {
    if (!isEmptyString(sku)) {
      const colors = queryBase64ToJSON<string[]>('colors') ?? [];
      const filterBy = queryBase64ToJSON<ICategorySpecificInfo[]>('filterBy') ?? [];
      cartStore.pageInventoryMasterSku = query.get('stockCode');
      cartStore.setProductSelectionQuery({ colors, filterBy });
      wishStore.pageInventoryMasterSku = cartStore.pageInventoryMasterSku;
      favoriteStore.pageInventoryMasterSku = cartStore.pageInventoryMasterSku;
      if (reloadPage.current === true) {
        cartStore.loadCartInfo(sku as string, currentClient?.id);
        favoriteStore.loadFavoritesInfo(sku as string, currentClient?.id);
        wishStore.loadWishInfo(sku as string, currentClient?.id);
      } else {
        cartStore.cartMatchedProductSelection();
      }
      reloadPage.current = true;
      cartStore.setLineItemCount(1, true);
    }

    try {
      mixpanel.track('Product View', {
        sku: cartStore.pageInventoryMasterSku,
      });
    } catch (err) {
      console.error(err);
    }

    return () => {
      cartStore.pageInventoryMasterSku = null;
      wishStore.pageInventoryMasterSku = null;
      favoriteStore.pageInventoryMasterSku = null;
    };
  }, [currentClient?.id, sku, query]);

  const loadComplimentaryProducts = useCallback(
    searchStore.useComplimentaryProducts(cartStore, inventoryStore, inventoryStore.loadSkuMasters),
    [cartStore, inventoryStore, searchStore],
  );

  useEffect(() => {
    loadComplimentaryProducts();
  }, [cartStore.skuList?.value.data, inventoryStore, loadComplimentaryProducts]);

  function shouldDisplaySheetTab(keys:TabKeysGuide) {
    if (keys === 'safety') {
      return safetyDataSheetUrls !== undefined && safetyDataSheetUrls.length > 0;
    } if (keys === 'technical') {
      return technicalSpecFiles !== undefined && technicalSpecFiles.length > 0;
    }
    return false;
  }
  const getCategoryCardViewLink = (refNo: string, idx: number): string => {
    let newPathQuery = categoryStore.breadCrumb.slice(0, idx).map((x) => x.refNo).join(',');
    if (newPathQuery.length > 0) {
      newPathQuery += ',';
    }
    return `/products/${categoryRef ?? 'category'}/${subCategoryRef ?? refNo}?path=${newPathQuery}${refNo}`;
  };
  useEffect(() => {
    categoryStore.getSubCategoryPath(cartStore.currentSkuItem?.categoryId ?? '');
  }, [cartStore.currentSkuItem?.categoryId]);
  return (
    <main
      id="productInfo"
      className={styles.prodInfo}
    >
      <Helmet>
        <title>
          {`${cartStore.currentInvItem?.description ?? 'Product'
          } - G. Fox`}

        </title>
        <script>
          {`
           function gtagSendEvent(url) {
            var callback = function () {
              if (typeof url === 'string') {
                window.location = url;
              }
            };
            gtag('event', 'ads_conversion_add_to_cart_1', {
              'event_callback': callback,
              'event_timeout': 2000,
              // <event_parameters>
            });
            return false;
          }
          `}
        </script>
      </Helmet>

      <div className={styles.backNavCon}>
        <FiChevronLeft className={styles.backNavArrow} onClick={handleGoBack} />
        <Text className={styles.backNavText} onClick={handleGoBack}>
          Back
        </Text>
        <div className={styles.backNavBorder} />
      </div>
      <div className={styles.filterBreadCrumb}>
        {categoryStore.pathBreadCrumbs.map((item, idx) => (
          <Link
            key={item.refNo}
            className={styles.filterBreadCrumbItem}
            href={getCategoryCardViewLink(item.refNo, idx)}
            onClick={(e) => {
              e.preventDefault();
              const link = getCategoryCardViewLink(item.refNo, idx);
              history.push(link);
            }}
          >
            <FiChevronRight />
            <Text className={styles.filterBreadCrumbText}>{item.name}</Text>
          </Link>
        ))}
      </div>

      <div className={styles.infoCon}>
        <div className={styles.carouselCon}>
          <>
            <Carousel className={styles.carousel}>
              <div className={styles.labelContainer}>
                <div className={styles.labelHold}>
                  {cartStore.currentInvItem?.masterStock?.onHold
                    === StockHoldOptions.PartialHold && (
                      <div className={styles.onHoldLabel}>Limited Stock</div>
                  )}

                  {cartStore.currentSkuItem?.new && (
                    <div className={styles.onHoldLabel}>New</div>
                  )}
                </div>

                {cartStore.isLoadingCartItems ? (
                  <div className={styles.carouselImgCon}>
                    <Skeleton active loading paragraph={{ rows: 12 }} />
                  </div>
                ) : (
                  <Image
                    src={`${imgURLPrefix}${selectedImage}`}
                    alt="Product image"
                    className={styles.carouselImg}
                    rootClassName={styles.carouselImgCon}
                    fallback={Images.FallbackImage}
                  />
                )}
              </div>
              {/* TODO: arrows */}
            </Carousel>
            <div className={styles.carouselExtraImages}>
              {/* TODO: images overflow */}
              {!cartStore.isLoadingCartItems
                && cartStore.currentInvItem?.productImages?.map((item, idx) => (
                  <Image
                    key={item}
                    src={`${imgURLPrefix}${item}`}
                    alt="Product image"
                    className={styles.carouselImgOther}
                    fallback={Images.FallbackImage}
                    preview={false}
                    onClick={() => cartStore.setActiveImageIdx(idx)}
                    rootClassName={[
                      styles.carouselImgOtherCon,
                      idx === cartStore.activeImageIdx
                      && styles.carouselImgOtherConActive,
                    ].join(' ')}
                  />
                ))}
            </div>
          </>
        </div>
        {cartStore.isLoadingCartItems ? (
          <div className={styles.productInfoCon}>
            <Skeleton active loading paragraph={{ rows: 12 }} />
          </div>
        ) : (
          <div className={styles.productInfoCon}>
            <Text className={styles.productSku}>
              {cartStore.currentInvItem?.sku}
            </Text>

            <Title level={2} className={styles.productName}>
              {cartStore.currentSkuItem?.productName}
            </Title>

            <div>
              <ul className={styles.productAttributesCon}>
                {React.Children.toArray(
                  cartStore.skuDescription.map((item) => <li>{item}</li>),
                )}
              </ul>
            </div>

            <Buttons.PrimaryBtn
              className={styles.productAttributesReadMore}
              type="text"
            >
              Read Less
            </Buttons.PrimaryBtn>

            <div className={styles.productSelectCon}>
              {React.Children.toArray(
                cartStore.currentInvItem?.categorySpecificInfo?.map((item) => (
                  <div
                    className={styles.productSelectItemCon}
                    onClick={() => handleChangeSelection()}
                    aria-hidden
                  >
                    <Text className={styles.productSelectItemName}>
                      {`${item.name}:`}
                      <FiChevronDown />
                    </Text>
                    <br />
                    <div className={styles.productSelectItemValueCon}>
                      <Text className={styles.productSelectItemValue}>
                        {item.value?.toString().replaceAll('|', ', ')}
                      </Text>
                    </div>
                  </div>
                )),
              )}
            </div>

            <Paragraph className={styles.backOrderText}>
              {stockMessage.message}
            </Paragraph>

            <Paragraph className={styles.deliveryTimeText}>
              Delivery time estimate 3-5 working days
            </Paragraph>

            <Text className={styles.quantityHeader}>Quantity:</Text>
            <div className={styles.quantityCon}>
              {!hasSelection && (
                <Buttons.CartCountBtn
                  // singleItem
                  quantity={cartStore.lineItemCount}
                  className={styles.cartCountCon}
                  onAddClick={() => cartStore.setLineItemCount(1)}
                  onMinusClick={() => cartStore.setLineItemCount(-1)}
                  handleQuantityChange={(e) => {
                    const value = e.target.value === '' ? 0 : Number(e.target.value);
                    cartStore.setLineItemCount(Number(value), true);
                  }}
                />
              )}

              <Buttons.PrimaryBtn
                className={styles.addToCartBtn}
                onClick={handleAddToCart}
              >
                Add To Cart
              </Buttons.PrimaryBtn>

              <Tooltip title="Add To Favorites">
                <Buttons.PrimaryBtn
                  type={favoriteslistSelected() ? 'primary' : 'default'}
                  className={styles.wishlistButton}
                  onClick={handleAddToFavorites}
                >
                  <StarOutlined className={styles.wishlistButtonIcon} />
                </Buttons.PrimaryBtn>
              </Tooltip>
            </div>
            {!hasSelection && (
              <PricingInformation
                pricing={cartStore?.currentInvItem}
                lineItems={cartStore?.lineItemCount}
                className={styles.priceCon}
                textClassName={styles.priceText}
                text2ClassName={styles.priceText2}
              />
            )}
            <Paragraph className={styles.accountHolderNoticeText}>
              {/* eslint-disable-next-line max-len */}
              Pricing will be discounted for resellers and bulk purchases. If you are a reseller or purchasing in large quantities, please contact sales@gfox.co.za or call your local branch to confirm your discounted price.
            </Paragraph>
            {cartStore.selectedProductItems.length > 0 && (
              <>
                <SelectedItemsTable
                  items={cartStore.selectedProductItems}
                  onRemoveProduct={cartStore.removeProduct}
                />

              </>
            )}
            <Tabs
              className={styles.detailsGuideCon}
              onTabClick={(keys) => setActiveTabGuide(keys as TabKeysGuide)}
            >
              {guideTab.map((item) => {
                if (shouldDisplaySheetTab(item.key)) {
                  return (
                    <Tabs.TabPane
                      key={item.key}
                      tabKey={item.key}
                      tab={<Text className={styles.detailsHeaderGuide}>{item.name}</Text>}
                    >
                      {item.component}
                    </Tabs.TabPane>
                  );
                }
                return null;
              })}
            </Tabs>

            {!isLoggedIn && (
              <Paragraph className={styles.backOrderText}>
                Displayed prices and stock are for our Johannesburg branch. To
                see details for other branches, please register or log in.
              </Paragraph>
            )}

            <div className={styles.accountHolderNotice}>
              <Text className={styles.accountHolderNoticeHeader}>
                Please Note:
              </Text>
              <Paragraph className={styles.accountHolderNoticeText}>
                Stock is updated daily, the quantity shown is subject to
                intraday movements.
              </Paragraph>
            </div>
          </div>
        )}
      </div>
      <div className={styles.detailsCon}>
        {tabHeaders.map((item) => (
          <div key={item.name} className={styles.details}>
            <div
              className={styles.detailsHeader}
              onClick={() => setActiveTabKey(item.key as TabKeysType)}
              aria-hidden
            >
              <Text
                className={[
                  styles.detailsHeaderText,
                  // TODO: make selected drop drown selectable
                  activeTabKey === item.key && styles.detailsHeaderTextActive,
                ].join(' ')}
              >
                {item.name}
              </Text>
              {activeTabKey === item.key ? (
                <FiChevronUp className={styles.detailsHeaderIcon} />
              ) : (
                <FiChevronDown className={styles.detailsHeaderIcon} />
              )}
            </div>
            {activeTabKey === item.key && item.component}
          </div>
        ))}
      </div>

      <Tabs
        activeKey={activeTabKey}
        className={styles.detailsConTab}
        onTabClick={(_) => setActiveTabKey(_ as TabKeysType)}
      >
        {tabHeaders.map((item) => (
          <Tabs.TabPane
            key={item.key}
            tabKey={item.key}
            tab={<Text className={styles.detailsHeaderText}>{item.name}</Text>}
          >
            {item.component}
          </Tabs.TabPane>
        ))}
      </Tabs>
      {!isSkuListEmpty() && (
        <>
          <Text className={styles.complimentaryHeader}>Used with</Text>
          <div className={styles.filterResultCardCon}>
            {React.Children.toArray(
              inventoryStore.complementaryProducts.value.data.map((item) => (
                <Cards.ProductCard
                  className={styles.filterResultCard}
                  color="red"
                  imageSrc={`${imgURLPrefix}${item.subCategoryLongImage}`}
                  imgClassName={styles.filterResultImg}
                  productName={item.productName}
                  productNameClassName={styles.filterResultPName}
                  optType="viewBtn"
                  removeBg
                  separator
                  shopLink={`/products/${categoryRef}/${subCategoryRef}/${item.sku}`}
                  viewLink={`/products/${categoryRef}/${subCategoryRef}/${item.sku}`}
                  pricing={{
                    masterStock: item.masterStock,
                    accountPricing: item.accountPricing,
                  }}
                />
              )),
            )}
          </div>
        </>
      )}

      <Modal
        title="Cart"
        className={styles.checkoutModal}
        visible={isModalOpen}
        onOk={handleGoToCheckout}
        onCancel={() => setIsModalOpen(false)}
        okText="View The Cart"
        cancelText="Continue Shopping"
        okButtonProps={{ style: { height: '3em' } }}
        cancelButtonProps={{ style: { height: '3em' } }}
        closeIcon={<IoClose />}
      >
        <div className={styles.checkoutModalBody}>
          <Image
            src={`${imgURLPrefix}${selectedImage}`}
            alt="Product image"
            className={styles.carouselImg}
            rootClassName={styles.checkoutModalImgCon}
            fallback={Images.FallbackImage}
          />
          <div className={styles.checkoutModalDetails}>
            <Text className={styles.checkoutModalDetailsHeader}>
              This item has been added to your shopping cart.
            </Text>
            <Text className={styles.checkoutModalInfo}>
              {cartStore.currentInvItem?.description ?? ''}
            </Text>
            <Text className={styles.checkoutModalInfo}>
              Quantity:
              {' '}
              {cartStore.lineItemCount}
            </Text>
          </div>
        </div>
      </Modal>
      <Modal
        title="Favorites-List"
        className={styles.checkoutModal}
        visible={isFavoritesModalOpen}
        onOk={handleGoToFavorites}
        onCancel={() => setisFavoritesModalOpen(false)}
        okText="View The Favorites List"
        cancelText="Continue Shopping"
        okButtonProps={{ style: { height: '3em' } }}
        cancelButtonProps={{ style: { height: '3em' } }}
        closeIcon={<IoClose />}
      >
        <div className={styles.checkoutModalBody}>
          <Image
            src={`${imgURLPrefix}${selectedImage}`}
            alt="Product image"
            className={styles.carouselImg}
            rootClassName={styles.checkoutModalImgCon}
            fallback={Images.FallbackImage}
          />
          <div className={styles.checkoutModalDetails}>
            <Text className={styles.checkoutModalDetailsHeader}>
              This item has been added to your shopping favorites List.
            </Text>
            <Text className={styles.checkoutModalInfo}>
              {cartStore.currentInvItem?.description ?? ''}
            </Text>
          </div>
        </div>
      </Modal>

      <Modals.ProductSelectionModal
        isVisible={showFilterModal}
        setIsVisible={setShowFilterModal}
        onSelectColor={(v) => handleOnViewModalSelection(v)}
      />
    </main>
  );
});

export default ProductInformation;
