import React from 'react';
import { observer } from 'mobx-react-lite';
import { DeliveryTypes } from '@bridgelabsdesign/gfox-api-client';
import Cards from '../../components/Cards';
import styles from './styles.module.css';
import stores from '../../stores/stores';
import { formatPrice } from '../../utils/pricing';
import Buttons from '../../components/Buttons';
import { useAuthContext } from '../../context/AuthContext';

const CheckoutDelivery = observer(() => {
  const { deliveryStore } = stores;
  const { currentClient } = useAuthContext();
  const isAccHolder = !!currentClient?.accountNumber;

  return (
    <div>
      {deliveryStore.deliveryOptions.map((option) => (
        <Cards.DeliveryCard
          key={option.refNo}
          className={styles.ordersCard}
          deliveryType={option.description}
          price={formatPrice(option?.deliveryOrders[0]?.price ?? 0)}
          value={option.refNo}
          onSelect={() => deliveryStore.setSelectedDelivery(option)}
          isSelected={option.refNo === deliveryStore.selectedDelivery?.refNo}
        />
      ))}

      {(!deliveryStore.deliveryOptions.some((opt) => opt.refNo === DeliveryTypes.ExpressDelivery) && !isAccHolder) && (
        <Buttons.LinkBtn
          type="link"
          link={{
            // eslint-disable-next-line no-restricted-globals
            href: `/account/profile?returnUrl=${location.pathname}`,
          }}
          style={{
            display: 'block',
            wordWrap: 'break-word',
            whiteSpace: 'normal',
          }}
        >
          Please click
          to add a valid address
        </Buttons.LinkBtn>
      )}
    </div>
  );
});

export default CheckoutDelivery;
