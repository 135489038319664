import { LegalDocuments } from '../../utils/legal';
import { ContactPageTab } from '../../pages/Contact/Contact';

export interface IFooterDataRoute {
  title: string;
  href: string;
  externalLink?: boolean
}

export interface IFooterData {
  header: string;
  routes: IFooterDataRoute[];
}

// TODO: use correct routes/href
const data: IFooterData[] = [
  {
    header: 'About',
    routes: [
      { title: 'Overview', href: '/about' },
      { title: 'History', href: '/about/history' },
      { title: ' B-BBEE', href: '/about/BBBEE' },
      // { title: 'Blog', href: '/about/blog' },
      // { title: 'Trending Products', href: '/products/trending' },
      { title: 'Careers', href: '/about/careers' },
    ],
  },
  {
    header: 'Products',
    routes: [],
  },
  {
    header: 'Support',
    routes: [
      { title: 'GFox Product Catalogue', href: '/products/catalogue' },
      { title: 'Privacy Policy', href: LegalDocuments.PRIVACY_POLICY, externalLink: true },
      { title: 'Legal', href: '/about/legal' },
      { title: 'Terms and Conditions', href: LegalDocuments.TRADING_TERMS, externalLink: true },
      { title: 'Returns Policy', href: LegalDocuments.RETURN_POLICY, externalLink: true },
      { title: 'Our Branches', href: `/contact?tab=${ContactPageTab.BRANCHES}` },
      { title: 'Size Guide', href: 'https://gfox-cdn.bridgelabs.design/documents/guides/sizingchart.pdf', externalLink: true },
      { title: 'Credit Applications', href: LegalDocuments.CREDIT_APPLICATION, externalLink: true },
      { title: 'Certificates', href: '/about/certificates' },
      { title: 'Customer Login', href: '/account/login' },
      // { title: 'Sitemap', href: '/about/Sitemap' }, // TODO: sitemap
    ],
  },
  {
    header: 'Contact us',
    routes: [
      { title: 'General Enquiries', href: `/contact?tab=${ContactPageTab.GENERAL_ENQUIRIES}` },
      { title: 'Embroidery', href: `/contact?tab=${ContactPageTab.GENERAL_ENQUIRIES}&` },
    ],
  },
];

export default data;
